import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Heading,
	VStack,
	Skeleton,
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { AppContext } from '../../../data/globalstate';
import { useApiManager } from '../../../domain/hooks';

const AccountHistoryEarning: React.FC = () => {
	const { user } = useContext(AppContext);
	const { useGetEarningsHistory } = useApiManager();
	const { data, isLoading, error } = useGetEarningsHistory({ UserId: user?.burstId ?? '' });
	const noTransactions = !data?.transactions || data.transactions.length <= 0;
	return (
		<VStack
			alignSelf={'stretch'}
			mt={'1rem'}
			backgroundColor={'white'}
			color={'secondary.500'}
			boxShadow={'0px 3px 6px #00000029'}
			alignItems={'flex-start'}
		>
			<Heading
				as="h2"
				textTransform="uppercase"
				backgroundColor={'primary.500'}
				color={'primaryText.500'}
				p="10px"
				alignSelf={'stretch'}
				fontSize={'x-large'}
			>
				EARNING ACTIVITY
			</Heading>
			{error && (
				<Alert status="error">
					<AlertIcon />
					<AlertTitle>Error getting your earning activities.</AlertTitle>
					<AlertDescription>{error.message}</AlertDescription>
				</Alert>
			)}
			<Skeleton isLoaded={!isLoading} w={'100%'}>
				<TableContainer width="100%">
					<Table variant="striped">
						<Thead>
							<Tr>
								<Th>DATE</Th>
								<Th>NAME</Th>
								<Th>POINTS</Th>
								<Th>CONFIRMATION NUMBER</Th>
							</Tr>
						</Thead>
						<Tbody>
							{noTransactions && (
								<Tr>
									<Td colSpan={4}>
										<Heading as={'h3'} textAlign={'center'}>
											No Earning Activity
										</Heading>
									</Td>
								</Tr>
							)}
							{data?.transactions?.map((v, i) => (
								<Tr key={`history_${i.toString()}`}>
									<Td>{v.transactionDate.toLocaleDateString('en-US')}</Td>
									<Td>{v.pointType}</Td>
									<Td>{v.totalPoints} PTS</Td>
									<Td>{v.transactionId}</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</Skeleton>
			{/* TODO Paging */}
		</VStack>
	);
};

export default AccountHistoryEarning;
