import React from 'react';
import { motion } from 'framer-motion';
import {
	Box,
	Heading,
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	VStack,
	useColorModeValue,
} from '@chakra-ui/react';
import NavigationBar from '../components/NavigationBar';
import CartBar from '../components/cart/CartBar';
import { ScrollRestoration } from 'react-router-dom';
import AccountDetails from '../components/user/AccountDetails';
import AccountEarning from '../components/user/AccountEarning';
import AccountHistoryEarning from '../components/user/AccountHistoryEarning';
import AccountHistoryReferral from '../components/user/AccountHistoryReferral';
import AccountAddresses from '../components/user/AccountAddresses';
import FooterThreeColumn from '../components/FooterThreeColumn';

const UserProfilePage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	return (
		<Box
			textAlign="center"
			fontSize="xl"
			ref={ref}
			backgroundColor={useColorModeValue('#EFE5D9', 'secondary.500')}
		>
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<VStack maxW="7xl" justifyContent={'center'} mx="auto" gap={5} mb={'2rem'}>
				<Heading as="h1" textTransform="uppercase" mt={'2rem'} alignSelf={'stretch'}>
					MY ACCOUNT
				</Heading>
				<Tabs variant="line" colorScheme="primary.500" isLazy isFitted alignSelf={'stretch'}>
					<TabList>
						<Tab>MY DETAILS</Tab>
						<Tab>EARNING ACTIVITY</Tab>
						<Tab>MY REFERRALS</Tab>
					</TabList>
					<TabPanels>
						<TabPanel p={0}>
							<VStack>
								<AccountDetails />
								<AccountAddresses />
								<AccountEarning />
							</VStack>
						</TabPanel>
						<TabPanel p={0}>
							<VStack>
								<AccountHistoryEarning />
							</VStack>
						</TabPanel>
						<TabPanel p={0}>
							<VStack>
								<AccountHistoryReferral />
							</VStack>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</VStack>
			<FooterThreeColumn />
		</Box>
	);
});

export const AnimatedUserProfilePage = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <UserProfilePage ref={ref} />),
);

export default UserProfilePage;
