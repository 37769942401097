import { useState } from 'react';
import {
	Button,
	FormControl,
	FormLabel,
	Heading,
	HStack,
	Select,
	Skeleton,
	Text,
	VStack,
} from '@chakra-ui/react';

const questions = [
	{
		name: 'How many questions to cross safely?',
		answers: [
			{ name: 'Five Questions', value: '5' },
			{ name: 'Three Questions', value: '3' },
			{ name: 'One Question', value: '1' },
		],
	},
	{
		name: 'What is your quest?',
		answers: [
			{ name: 'To gain immortality', value: 'immortality' },
			{ name: 'To seek the holy grail', value: 'holy grail' },
			{ name: 'To get another shrubbery', value: 'shrubbery' },
		],
	},
	{
		name: 'What is your name?',
		answers: [
			{ name: 'Sir Lancelot', value: 'lancelot' },
			{ name: 'Sir Galahad', value: 'galahad' },
			{ name: 'Sir Robin', value: 'robin' },
		],
	},
	{
		name: 'What is the airspeed of an unladen swallow?',
		answers: [
			{ name: 'African', value: 'african' },
			{ name: 'European', value: 'european' },
			{ name: 'British', value: 'British' },
		],
	},
];
const title = {
	action: 'COMPLETE TO EARN A $10 EGIFT CARD',
	submitted: '$10 EGift Card Earned ',
};

const AccountEarning: React.FC = () => {
	const [showSubmitted, setShowSubmitted] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// TODO Get Dynamic Data from where?

	function timedThanks() {
		// TODO Build Out Submit Functionality
		setIsLoading(true);
		setTimeout(() => {
			setShowSubmitted(true);
			setIsLoading(false);
		}, 2500);
	}

	return (
		<VStack
			alignSelf={'stretch'}
			mt={'1rem'}
			backgroundColor={'white'}
			color={'secondary.500'}
			boxShadow={'0px 3px 6px #00000029'}
		>
			<Heading
				as="h2"
				textTransform="uppercase"
				backgroundColor={'primary.500'}
				color={'primaryText.500'}
				p="10px"
				alignSelf={'stretch'}
				fontSize={'x-large'}
			>
				{title.action}
			</Heading>
			{!showSubmitted && (
				<Skeleton isLoaded={!isLoading} alignSelf={'stretch'} p={'1rem'}>
					<VStack gap={5}>
						{questions.map((question, idx) => {
							return (
								<FormControl key={`question_${idx.toString()}`}>
									<HStack>
										<FormLabel flex={1}>{question.name}</FormLabel>
										<Select placeholder="Select" size="lg" maxW={'xl'}>
											{question.answers.map((answer, i) => {
												return (
													<option key={i} value={answer.value}>
														{answer.name}
													</option>
												);
											})}
										</Select>
									</HStack>
								</FormControl>
							);
						})}
						<Button
							colorScheme="secondary"
							size="lg"
							textTransform="uppercase"
							mx="20px"
							onClick={timedThanks}
						>
							Save
						</Button>
					</VStack>
				</Skeleton>
			)}
			{showSubmitted && (
				<VStack justifyContent="center" alignItems="center" p={'2rem'}>
					<Heading as="h3">Good Job!</Heading>
					<Text>You have earned points by completing this action!</Text>
				</VStack>
			)}
		</VStack>
	);
};

export default AccountEarning;
