import { Box, Flex, HStack, Img, Link, Text, VStack } from '@chakra-ui/react';
import { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AppContext } from '../../data/globalstate';
import { expireCookie } from '../../domain/utils/CookieUtils';

const footerLinks = {
	column1: [
		{ text: 'earn points', url: '/earn-points' },
		{ text: 'redeem rewards', url: '/rewards' },
		{ text: 'contact us', url: '/contact-us' },
		{ text: 'how it works', url: '/how-it-works' },
	],
	column2: [
		{ text: 'privacy policy', url: '#' },
		{ text: 'terms & conditions', url: '#' },
		{ text: 'customer support', url: '#' },
		{ text: 'faq', url: '#' },
	],
};

const FooterTwoColumnBackground: React.FC = () => {
	const { user, setUser } = useContext(AppContext);
	const navigate = useNavigate();
	return (
		<Flex
			as="section"
			minH="250px"
			position="relative"
			justifyContent={'flex-start'}
			minHeight={'700px'}
		>
			<VStack
				maxW={'1280px'}
				zIndex={1}
				position={'relative'}
				mx={'auto'}
				alignItems={'flex-start'}
				justifyContent={'flex-start'}
				flex={1}
			>
				<NavLink to="/">
					<Img
						src="https://burst-staging.ebbo.com/media/logo/stores/8/PinnacleOutdoor_header_logo_2x.png"
						alt="Logo"
						w={'134px'}
						objectFit={'contain'}
						mt={'2rem'}
						mb={'1rem'}
					/>
				</NavLink>
				<HStack gap={40} alignItems={'flex-start'}>
					<VStack alignItems={'flex-start'} maxW={'20%'}>
						{footerLinks.column1.map((v, i) => (
							<Link
								as={NavLink}
								to={v.url}
								key={`link_c1_${i.toString()}`}
								textTransform={'uppercase'}
								color="secondary.500"
							>
								{v.text}
							</Link>
						))}
						{/* Signout & View Account needs to be static */}
						{user && (
							<>
								<Link
									key={`viewaccount_link`}
									textTransform={'uppercase'}
									color="secondary.500"
									onClick={() => {
										navigate('/profile');
									}}
								>
									view account
								</Link>
								<Link
									key={`signout_link`}
									textTransform={'uppercase'}
									color="secondary.500"
									onClick={() => {
										// Logout
										user.cognitoUser.signOut();
										expireCookie('accessToken');
										setUser(undefined);
										navigate('/');
									}}
								>
									sign out
								</Link>
							</>
						)}
					</VStack>
					<VStack alignItems={'flex-start'} maxW={'20%'}>
						{footerLinks.column2.map((v, i) => (
							<Link
								as={NavLink}
								to={v.url}
								key={`link_c1_${i.toString()}`}
								textTransform={'uppercase'}
								color="secondary.500"
							>
								{v.text}
							</Link>
						))}
						<Text fontSize={'small'} textAlign={'left'} color={'black'}>
							&copy; Clarus Commerce LLC DBA ebbo. All rights reserved. CLARUS COMMERCE CONFIDENTIAL
							INFORMATION. All third-party trademarks and logos appearing on this site or the
							property of their respective owners and do not imply any affiliation with or
							endorsement by them. Examples are for FPO purposes. Final creative is subject to legal
							review.
						</Text>
						<Text fontSize={'small'} textAlign={'left'} color={'black'}>
							*NO PURCHASE NECESSARY. A purchase will not increase your chances of winning.
							Sweepstakes begins at 12:00 PM CT [PROMOTION START DATE] and ends at 11:59:59 PM CT
							[PROMOTION END DATE]. Open only to legal residents of [ELIGIBLE STATES] who are XX
							years or older. Click Here for Official Rules, including how to enter, free method of
							entry instructions, odds, prize details and restrictions. Void where prohibited.”
						</Text>
					</VStack>
				</HStack>
			</VStack>
			<Flex
				id="image-wrapper"
				position="absolute"
				insetX="0"
				insetY="0"
				w="full"
				h="full"
				overflow="hidden"
				align="center"
				bg={'white'}
			>
				<Box position="relative" w="full" h="full">
					<Img
						src={'/media/images/Footer_Img.webp'}
						alt="Footer Background"
						w="full"
						h="full"
						objectFit="cover"
						objectPosition="top bottom"
						position="absolute"
					/>
				</Box>
			</Flex>
		</Flex>
	);
};
export default FooterTwoColumnBackground;
