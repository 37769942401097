export const objectToUrlParams = (obj: unknown): string => {
	let s = '';
	for (const key in obj as object) {
		if (s !== '') {
			s += '&';
		}
		// @ts-expect-error Allow Indexing of Generic Object
		// eslint-disable-next-line
		s += key + '=' + encodeURIComponent(obj[key]);
	}
	// console.log("'" + s + "'");
	return s;
};
