import {
	Box,
	BoxProps,
	Flex,
	FlexProps,
	IconButton,
	IconButtonProps,
	Stack,
	StackProps,
	Link,
	VStack,
	Heading,
	Text,
	Button,
	LinkProps,
	useToast,
	ToastId,
} from '@chakra-ui/react';
import { KeenSliderOptions, useKeenSlider } from 'keen-slider/react'; // using version 6.6.10
import { forwardRef, useContext, useEffect, useRef } from 'react';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { AppContext } from '../../data/globalstate';
import { useApiManager } from '../../domain/hooks';
import { INewTransactionRequest } from '../../domain/models';
import { v7 as uuidv7 } from 'uuid';
import { useNavigate } from 'react-router-dom';

// TODO - Refactor

export const Carousel = forwardRef<HTMLDivElement, FlexProps>(function Carousel(props, ref) {
	return (
		<Flex
			ref={ref}
			className="chakra-carousel"
			overflow="hidden"
			position="relative"
			userSelect="none"
			{...props}
		/>
	);
});

const CarouselSlide = (props: BoxProps) => (
	<Box
		className="chakra-carousel__slide"
		position="relative"
		overflow="hidden"
		width="100%"
		minH="100%"
		{...props}
	/>
);

const useCarousel = (options?: KeenSliderOptions) => {
	const defaultOptions = { selector: '.chakra-carousel__slide' };
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
	return useKeenSlider<HTMLDivElement>({ ...defaultOptions, ...options });
};

const CarouselIconButton = (props: IconButtonProps) => (
	<IconButton
		{...props}
		fontSize="2xl"
		isRound
		boxShadow="-4px 4px 6px rgba(24, 37, 170, .2)"
		bg={'white'}
		color={'secondary.500'}
		_hover={{
			bg: 'gray.200',
		}}
		_focus={{ boxShadow: 'inerhit' }}
		_focusVisible={{ boxShadow: 'outline' }}
		w={'40px'}
		h={'40px'}
	/>
);

// FIXME - We're gonna need to figure out how to do this once we have earning actions from burst.
interface IEarningAction {
	points: number;
	heading: string;
	description: string;
	Children: React.FC;
}

const ActionComponent: React.FC<{
	text: string;
	earningAction: IEarningAction;
	linkProps?: LinkProps;
}> = ({ text, linkProps, earningAction }) => {
	const { user } = useContext(AppContext);
	const { useClaimEarningAction } = useApiManager();
	const { mutateAsync, error, isSuccess, isPending } = useClaimEarningAction();
	const toast = useToast();
	const toastIdRef = useRef<ToastId>();
	const navigate = useNavigate();

	// FIXME - Need to change how we're accessing the Toast. Toast needs to move up in the DOM and get passed into this component.

	useEffect(() => {
		if (error) {
			if (toastIdRef.current) {
				toast.update(toastIdRef.current, {
					title: `Error Claiming ${earningAction.heading}`,
					description: error.message,
					status: 'error',
					duration: null,
				});
			} else {
				toastIdRef.current = toast({
					title: `Error Claiming ${earningAction.heading}`,
					description: error.message,
					status: 'error',
					id: 'claim',
					duration: null,
				});
			}
		}
		// eslint-disable-next-line
	}, [error]);

	useEffect(() => {
		if (isPending) {
			if (toastIdRef.current) {
				toast.update(toastIdRef.current, {
					title: `Claiming ${earningAction.heading}...`,
					status: 'loading',
					duration: null,
				});
			} else {
				toastIdRef.current = toast({
					title: `Claiming ${earningAction.heading}...`,
					status: 'loading',
					id: 'claim',
					duration: null,
				});
			}
		}
		// eslint-disable-next-line
	}, [isPending]);

	useEffect(() => {
		if (isSuccess) {
			if (toastIdRef.current) {
				toast.update(toastIdRef.current, {
					title: `${earningAction.heading} Claimed Successfully!`,
					status: 'success',
					duration: 5000,
				});
			} else {
				toastIdRef.current = toast({
					title: `${earningAction.heading} Claimed Successfully!`,
					status: 'success',
					id: 'claim',
					duration: 5000,
				});
			}
		}
		// eslint-disable-next-line
	}, [isSuccess]);

	return (
		<Link
			{...linkProps}
			textDecor={'underline'}
			textTransform={'uppercase'}
			onClick={() => {
				if (!user) {
					// TODO Login to claim
					// console.log('Login to claim');
					navigate('/login');
					return;
				}
				// TODO Claim Action
				console.log('Claim Action');
				const ea: INewTransactionRequest = {
					transactionCode: uuidv7(),
					transactionDescription: 'birthday rewards',
					membershipCode: user.burstId,
					items: [
						{
							quantity: 1,
							grossValue: 100,
							productKey: '',
							properties: { profile: '1', Activity: 'true' },
						},
					],
					transactionTypeCode: null,
					currencyCode: 'USD',
					partnerCode: null,
					properties: null,
					alternateCustomerId: null,
				};
				mutateAsync(ea)
					.then((v) => {
						console.log('New Transaction Success :: ', v);
						// TODO
					})
					.catch((e: unknown) => {
						console.error(e);
						// TODO
					});
			}}
		>
			{text}
		</Link>
	);
};

const sampleEarningOptions: IEarningAction[] = [
	{
		points: 100,
		heading: 'Gear Up',
		description: 'Earn 100 points for every $1 you spend at Pinnacle!',
		Children: () => {
			return (
				<Link textDecor={'underline'} textTransform={'uppercase'}>
					Shop Now
				</Link>
			);
		},
	},
	{
		points: 100,
		heading: 'Share & Earn',
		description:
			'Share or post your unique link to Peak Rewards and get 100 points for every friend that joins. (Up to 10 friends.).',
		Children: () => {
			return (
				<VStack>
					<Link textDecor={'underline'} textTransform={'uppercase'}>
						copy my unique link
					</Link>
					<Text>— or —</Text>
					<Link textDecor={'underline'} textTransform={'uppercase'}>
						Get my unique code
					</Link>
				</VStack>
			);
		},
	},
	{
		points: 100,
		heading: 'birthday rewards',
		description: 'Get 100 points on your Birthday!',
		Children: () => {
			const { user } = useContext(AppContext);
			const { useClaimEarningAction } = useApiManager();
			const { mutateAsync } = useClaimEarningAction();
			const navigate = useNavigate();
			return (
				<Link
					textDecor={'underline'}
					textTransform={'uppercase'}
					onClick={() => {
						if (!user) {
							// TODO Login to claim
							console.log('Login to claim');
							navigate('/login');
							return;
						}
						// TODO Claim Action
						console.log('Claim Action');
						const ea: INewTransactionRequest = {
							transactionCode: uuidv7(),
							transactionDescription: 'birthday rewards',
							membershipCode: user.burstId,
							items: [
								{
									quantity: 1,
									grossValue: 100,
									productKey: '',
									properties: { profile: '1', Activity: 'true' },
								},
							],
							transactionTypeCode: null,
							currencyCode: 'USD',
							partnerCode: null,
							properties: null,
							alternateCustomerId: null,
						};
						mutateAsync(ea)
							.then((v) => {
								console.log('New Transaction Success :: ', v);
								// TODO
							})
							.catch((e: unknown) => {
								console.error(e);
								// TODO
							});
					}}
				>
					Claim Points
				</Link>
			);
		},
	},
	{
		points: 100,
		heading: 'birthday rewards',
		description: 'Get 100 points on your Birthday!',
		Children: () => {
			return (
				<Link textDecor={'underline'} textTransform={'uppercase'}>
					Claim Points
				</Link>
			);
		},
	},
	{
		points: 100,
		heading: 'birthday rewards',
		description: 'Get 100 points on your Birthday!',
		Children: () => {
			return (
				<Link textDecor={'underline'} textTransform={'uppercase'}>
					Claim Points
				</Link>
			);
		},
	},
];

type ElementType<T extends readonly unknown[]> = T extends readonly (infer ElementType)[]
	? ElementType
	: never;

type TEarningOptions = ElementType<(typeof sampleEarningOptions)[]>;

interface GalleryProps {
	data: TEarningOptions;
	rootProps?: StackProps;
}

const Gallery: React.FC<GalleryProps> = (props) => {
	const { data, rootProps } = props;

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const [ref, slider] = useCarousel({
		loop: true,
		mode: 'free-snap',
		slides: {
			perView: data.length < 3 ? data.length : 3,
			spacing: 20,
		},
	});

	return (
		<Stack {...rootProps} minH={'200px'} w={'100%'}>
			<Box
				position="relative"
				sx={{
					_hover: {
						'> button': {
							display: 'inline-flex',
						},
					},
				}}
			>
				{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
				<Carousel ref={ref}>
					{data.map((item, i) => {
						const ChildNode = item.Children;
						return (
							<CarouselSlide
								key={`slide_${i.toString()}`}
								background={'url("/media/images/chart-bg.webp")'}
							>
								<VStack
									m="25px"
									p={'20px'}
									height={'calc(100% - 40px)'}
									justifyContent={'space-between'}
									alignItems={'center'}
									backgroundColor={'rgba(1, 44, 66, .75)'}
									textAlign={'center'}
									color={'white'}
								>
									<VStack
										w={'100px'}
										h={'100px'}
										gap={0}
										borderRadius={'50%'}
										border={'2px solid #fff'}
										backgroundColor={'primary.500'}
										display={'flex'}
										flexDirection={'column'}
										alignItems={'center'}
										justifyContent={'center'}
										color={'white'}
									>
										<Heading as={'span'} size={'lg'} fontWeight={'bold'}>
											{item.points}
										</Heading>
										<Text fontSize={'lg'} textTransform={'uppercase'}>
											Points
										</Text>
									</VStack>
									<Heading
										as="h1"
										size="xl"
										fontWeight="extrabold"
										textTransform="uppercase"
										maxW="md"
									>
										{item.heading}
									</Heading>
									<Text fontSize={{ md: '2xl' }} mt="4" maxW="sm">
										{item.description}
									</Text>
									{/* {item.children} */}
									{/* TODO Action Component? */}
									<ChildNode />
									<ActionComponent text="claim" earningAction={item} />
								</VStack>
							</CarouselSlide>
						);
					})}
				</Carousel>
				<CarouselIconButton
					pos="absolute"
					left="-20px"
					top="50%"
					transform="translateY(-50%)"
					// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
					onClick={() => slider.current?.prev()}
					icon={<IoChevronBackOutline />}
					aria-label="Previous Slide"
				/>
				<CarouselIconButton
					pos="absolute"
					right="-20px"
					top="50%"
					transform="translateY(-50%)"
					// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
					onClick={() => slider.current?.next()}
					icon={<IoChevronForwardOutline />}
					aria-label="Next Slide"
				/>
			</Box>
		</Stack>
	);
};

const EarningGallery: React.FC = () => {
	return (
		<Flex width={'100%'} mt={'20px'} position={'relative'} bg={'#fdf9f5'} justifyContent={'center'}>
			<VStack width={'100%'} maxW={'1280px'}>
				<Heading my={'20px'} color={'secondary.500'}>
					Start Earning
				</Heading>
				<Gallery data={sampleEarningOptions} />
				<Button
					textTransform="uppercase"
					variant="solid"
					color="white"
					bg="secondary.500"
					_hover={{ opacity: '0.8' }}
					size="lg"
					my={'20px'}
				>
					EARN POINTS
				</Button>
			</VStack>
		</Flex>
	);
};
export default EarningGallery;
