import {
	Button,
	Center,
	Divider,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	IconButton,
	Img,
	Input,
	InputGroup,
	InputRightElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Progress,
	Select,
	Stack,
	Text,
	ToastId,
	useDisclosure,
	useToast,
	VStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import CartBar from '../components/cart/CartBar';
import NavigationBar from '../components/NavigationBar';
import { ScrollRestoration, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { HiEyeOff, HiEye } from 'react-icons/hi';
import { allCountries } from 'country-region-data';
import { passwordStrength } from 'check-password-strength';
// import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { AppContext } from '../../data/globalstate';
import { IAddressDto } from '../../domain/models/api/IAddressDto';
import { useApiManager, useAuthManager } from '../../domain/hooks';
import WelcomeSidebar from '../components/contentBlocks/WelcomeSidebar';
import { CognitoUser } from 'amazon-cognito-identity-js';
import FooterThreeColumn from '../components/FooterThreeColumn';
import DateOfBirthInput from '../components/inputs/DateOfBirthInput';

const JoinValidationScheme = yup.object({
	firstName: yup.string().required('First Name is Required'),
	lastName: yup.string().required('Last Name is Required'),
	phoneNumber: yup
		.string()
		.required('Phone Number is Required')
		.matches(
			/(^\([2-9][\d]{2}\) [\d]{3}-[\d]{4}$)|(^[2-9][\d]{9}$)|(^[2-9][\d]{2}-[\d]{3}-[\d]{4}$)/,
			'Invalid Phone Number, please enter a valid phone number',
		),
	address1: yup.string().required('Address is Required'),
	country: yup.string().required('Country is Required'),
	state: yup.string().required('State/Province is Required'),
	city: yup.string().required('City is Required'),
	zip: yup
		.string()
		.required('Zip/Postal Code is Required')
		.matches(
			/(^[\d]{5}$)|(^[\d]{5}-[\d]{4}$)/,
			'Invalid Zip/Postal Code, please enter a valid Zip/Postal Code',
		),
	email: yup.string().email('Must be a valid Email Address!').required('Email is Required!'),
	password: yup
		.string()
		.required('Password is Required')
		.min(8, 'Password is too short')
		.max(25, 'Password is too long')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
			'Password must contain at least one uppercase, one lowercase, one special character, and one number with a minimum of eight characters',
		),
	confirmPassword: yup
		.string()
		.required('Confirm Password is Required')
		.oneOf([yup.ref('password')], 'Passwords must match'),
});
const confirmValidationScheme = yup.object({
	confirmationCode: yup.string().required('Confirmation Code is Required'),
});
interface IJoinForm {
	firstName?: string;
	lastName?: string;
	dateOfBirth?: Date;
	allowRemoteAssistance: boolean;
	phoneNumber?: string;
	address1?: string;
	address2?: string;
	country: string;
	state?: string;
	city?: string;
	zip?: string;
	email?: string;
	password?: string;
	confirmPassword?: string;
}
interface IStateData {
	name: string;
	code: string;
}

interface IConfirmForm {
	confirmationCode?: string;
}

const JoinPage = React.forwardRef<HTMLDivElement, unknown>((props, ref) => {
	const { user, setUser } = useContext(AppContext);
	const nav = useNavigate();
	const { isOpen: showPassword, onToggle: onToggleShowPassword } = useDisclosure();
	const { isOpen: showConfirmPassword, onToggle: onToggleShowConfirmPassword } = useDisclosure();
	const {
		useRegister: useAuthRegister,
		useConfirmRegistration,
		useLogin: authLogin,
	} = useAuthManager();
	const { useRegister: useApiRegister, useLogin: apiLogin } = useApiManager();
	const { mutateAsync: authConfirmRegistration } = useConfirmRegistration();
	const { mutateAsync: authRegister } = useAuthRegister();
	const { mutateAsync: apiRegister } = useApiRegister();
	const { mutateAsync: doAuthLogin } = authLogin();
	const { mutateAsync: doApiLogin } = apiLogin();

	const toast = useToast();
	const toastIdRef = React.useRef<ToastId>();

	const [stateData, setStateData] = useState<IStateData[]>([]);
	const [pwStr, setPwStr] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
	const [newCognitoUser, setNewCognitoUser] = useState<CognitoUser | undefined>(undefined);
	const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
	const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

	const formik = useFormik<IJoinForm>({
		initialValues: {
			firstName: '',
			lastName: '',
			dateOfBirth: undefined,
			allowRemoteAssistance: false,
			phoneNumber: '',
			address1: '',
			address2: '',
			country: 'US',
			state: '',
			city: '',
			zip: '',
			email: '',
			password: '',
			confirmPassword: '',
		},
		validationSchema: JoinValidationScheme,
		// eslint-disable-next-line @typescript-eslint/require-await
		onSubmit: async (values) => {
			if (values.firstName && values.lastName && values.email && values.password) {
				toastIdRef.current = toast({
					title: 'Registering...',
					status: 'loading',
					id: 'register',
					duration: null,
				});
				setLoading(true);

				// NOTE: Cognito First Registration
				// authRegister
				// 	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				// 	.mutateAsync({
				// 		userName: values.email,
				// 		password: values.password,
				// 		firstName: values.firstName,
				// 		lastName: values.lastName,
				// 	})
				// 	.then(() => {
				// 		// console.log('Register Successful');
				// 		// completeRegistration(cognitoUser);
				// 		// console.log(formik.values);
				// 		// setLoading(false);
				// 		const addr: IAddress = {
				// 			address1: values.address1,
				// 			address2: values.address2,
				// 			city: values.city,
				// 			state: values.state,
				// 			country: values.country,
				// 			zipCode: values.zip,
				// 		};

				// 		apiRegister
				// 			.mutateAsync({
				// 				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				// 				userName: values.email!,
				// 				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				// 				password: values.password!,
				// 				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				// 				firstName: values.firstName!,
				// 				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				// 				lastName: values.lastName!,
				// 				address: addr,
				// 			})
				// 			.then(() => {
				// 				setNewCognitoUser(cognitoUser);
				// 				toast.closeAll();
				// 				setShowSuccessModal(true);
				// 			})
				// 			.catch((err: unknown) => {
				// 				console.error(err);
				// 				// TODO Notify
				// 				// TODO Delete last user from cognito as we had a burst registration error and we don't want to lock them out from registering causing them to have to contact support.
				// 			});
				// 	})
				// 	.catch((err: unknown) => {
				// 		console.error('Register Error');
				// 		setLoading(false);
				// 		alert((err as Error).message || JSON.stringify(err));
				// 	});

				// NOTE: Burst First Registration
				const addr: IAddressDto = {
					address1: values.address1,
					address2: values.address2,
					city: values.city,
					state: values.state,
					country: values.country,
					zipCode: values.zip,
				};
				apiRegister({
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					address: addr,
					birthdate: values.dateOfBirth ?? new Date('1900-01-01T00:00:00'),
					phone: values.phoneNumber ?? null,
					campaign: null,
					promotionCode: null,
					authId: null,
				})
					.then((burstMember) => {
						console.log('apiRegister::Success', burstMember);
						// setNewCognitoUser(cognitoUser);
						// toast.closeAll();
						// setShowSuccessModal(true);
						authRegister({
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							userName: values.email!,
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							password: values.password!,
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							firstName: values.firstName!,
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							lastName: values.lastName!,
							burstId: burstMember.id ?? undefined,
						})
							.then((v) => {
								console.log('authRegister::Success', v);
								setNewCognitoUser(v);
								toast.closeAll();
								// setShowSuccessModal(true);
								setShowConfirmModal(true);
								// formik.resetForm();
							})
							.catch((err: unknown) => {
								console.error('Register Error');
								alert((err as Error).message || JSON.stringify(err));
							})
							.finally(() => {
								setLoading(false);
							});
					})
					.catch((err: unknown) => {
						console.error(err);
						setLoading(false);
						alert((err as Error).message || JSON.stringify(err));
					});
			}
		},
	});
	const formikConfirm = useFormik<IConfirmForm>({
		initialValues: {
			confirmationCode: undefined,
		},
		validationSchema: confirmValidationScheme,
		onSubmit: (values) => {
			if (newCognitoUser && values.confirmationCode) {
				setConfirmLoading(true);
				toastIdRef.current = toast({
					title: 'Confirming Account...',
					status: 'loading',
					id: 'confirming',
					duration: null,
				});
				authConfirmRegistration({
					cognitoUser: newCognitoUser,
					confirmationCode: values.confirmationCode,
				})
					.then(() => {
						// Success

						if (toastIdRef.current) {
							toast.update('confirming', {
								title: 'Account Confirmed!',
								description: 'You may now login.',
								status: 'success',
								duration: 5000,
							});
						} else {
							toastIdRef.current = toast({
								title: 'Account Confirmed!',
								description: 'You may now login.',
								status: 'success',
								id: 'confirming',
								duration: 5000,
							});
						}
						// TODO Sign in and Navigate to Earn Page.
						if (formik.values.email && formik.values.password) {
							doAuthLogin({ userName: formik.values.email, password: formik.values.password })
								.then((res) => {
									// console.log('Login Successful');
									doApiLogin({ accountNumber: res.burstId })
										.then((v) => {
											// console.log(v);
											setUser({
												cognitoUser: res.cognitoUser,
												burstId: res.burstId,
												burstProfile: v,
											});

											// if (toastIdRef.current) {
											// 	toast.update(toastIdRef.current, {
											// 		title: 'Login Successful!',
											// 		status: 'success',
											// 		isClosable: false,
											// 		duration: 500,
											// 	});
											// }
											nav('/earn-points');
										})
										.catch((e: unknown) => {
											// TODO: Log error
											console.error(e);
											// if (toastIdRef.current) {
											// 	toast.update(toastIdRef.current, {
											// 		title: 'Profile Error',
											// 		description: JSON.stringify(e),
											// 		status: 'error',
											// 		isClosable: true,
											// 		duration: null,
											// 	});
											// }
										})
										.finally(() => {
											setLoading(false);
											setShowConfirmModal(false);
											setNewCognitoUser(undefined);
											formikConfirm.resetForm();
											formik.resetForm();
										});
								})
								.catch((e: unknown) => {
									console.error('Login Error', e);
									// if (toastIdRef.current) {
									// 	toast.update(toastIdRef.current, {
									// 		title: 'Login Error',
									// 		description: (e as Error).message || JSON.stringify(e),
									// 		status: 'error',
									// 		isClosable: true,
									// 		duration: null,
									// 	});
									// }
									setLoading(false);
									setShowConfirmModal(false);
									setNewCognitoUser(undefined);
									formikConfirm.resetForm();
									formik.resetForm();
								});
						} else {
							setShowConfirmModal(false);
							setNewCognitoUser(undefined);
							formikConfirm.resetForm();
						}
					})
					.catch((err: unknown) => {
						console.error(err);
						// REVIEW: Should we show a resend code option here?
						if (toastIdRef.current) {
							toast.update('confirming', {
								title: 'Account Confirmation Error!',
								description: (err as Error).message || JSON.stringify(err),
								status: 'error',
								duration: null,
								isClosable: true,
							});
						} else {
							toastIdRef.current = toast({
								title: 'Account Confirmation Error!',
								description: (err as Error).message || JSON.stringify(err),
								status: 'error',
								duration: null,
								isClosable: true,
								id: 'confirming',
							});
						}
						setLoading(false);
					});
				// .finally(() => {
				// 	setConfirmLoading(false);
				// });
			}
		},
	});

	// Load States on Country Change
	useEffect(() => {
		const countryData = allCountries.find((v) => v[1] === formik.values.country);
		if (countryData) {
			const states = countryData[2];
			const newStates: IStateData[] = [];
			states.forEach((v) => {
				newStates.push({ name: v[0], code: v[1] });
			});
			setStateData(newStates);
		}
	}, [formik.values.country]);

	// Calculate Password Strength
	useEffect(() => {
		const strRes = passwordStrength(formik.values.password ? formik.values.password : '', [
			{
				id: 0,
				value: 'Too weak',
				minDiversity: 0,
				minLength: 0,
			},
			{
				id: 1,
				value: 'Weak',
				minDiversity: 2,
				minLength: 6,
			},
			{
				id: 2,
				value: 'Medium',
				minDiversity: 4,
				minLength: 8,
			},
			{
				id: 3,
				value: 'Strong',
				minDiversity: 4,
				minLength: 13,
			},
		]);
		// console.log(strRes);
		let val = 0;
		switch (strRes.id) {
			case 1:
				val = 33;
				break;
			case 2:
				val = 66;
				break;
			case 3:
				val = 100;
				break;
			default:
				val = 0;
				break;
		}
		// console.log(val);
		setPwStr(val);
	}, [formik.values.password]);

	if (user) {
		return (
			<Flex
				textAlign="center"
				fontSize="xl"
				ref={ref}
				direction={'column'}
				justifyContent={'center'}
			>
				<ScrollRestoration />
				<CartBar />
				<NavigationBar />
				<VStack gap={4} py={4}>
					<Heading>Hi {user.burstProfile.firstName}! You've already registered!</Heading>
				</VStack>
				<FooterThreeColumn />
			</Flex>
		);
	}

	return (
		<Flex textAlign="center" fontSize="xl" ref={ref} direction={'column'} justifyContent={'center'}>
			<ScrollRestoration />
			<CartBar />
			<NavigationBar />
			<Stack
				direction={{ base: 'column', lg: 'row' }}
				spacing={{ base: '12', lg: '16' }}
				flex="1"
				maxW={'1280px'}
				alignSelf={'center'}
				mb={'30px'}
			>
				<VStack gap={0}>
					<WelcomeSidebar />
					<VStack p={'30px'} bgColor={'#012d43'} color={'white'} w={'100%'}>
						<Heading as={'h2'} textTransform={'uppercase'} size={'md'}>
							the adventure starts now
						</Heading>
						<Img
							src="https://burst-staging.ebbo.com/media/wysiwyg/Pinnacle/customer/Graphic_withwords_2x.png"
							alt="Logo"
							maxW={'100%'}
							w={'350px'}
							h={'auto'}
							objectFit={'contain'}
							mt={'2rem'}
							// mb={'1rem'}
						/>
					</VStack>
				</VStack>
				<VStack minW={'55%'} gap={4}>
					<Heading as={'h1'} textTransform={'uppercase'} mt={'40px'}>
						create an account
					</Heading>
					{/* SECTION Your Information Section */}
					<HStack justifyContent={'space-between'} alignItems={'center'} w={'100%'} mt={'20px'}>
						<Text fontWeight={'bold'}>Your Information</Text>
						<Text fontSize={'sm'}>* indicates required field</Text>
					</HStack>
					<FormControl isRequired isInvalid={Boolean(formik.errors.firstName)}>
						<FormLabel htmlFor="firstName">First Name</FormLabel>
						<Input
							id="firstName"
							type="text"
							name="firstName"
							required
							value={formik.values.firstName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						{formik.errors.firstName && (
							<FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isRequired isInvalid={Boolean(formik.errors.lastName)}>
						<FormLabel htmlFor="lastName">Last Name</FormLabel>
						<Input
							id="lastName"
							type="text"
							name="lastName"
							required
							value={formik.values.lastName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						{formik.errors.lastName && (
							<FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isInvalid={Boolean(formik.errors.dateOfBirth)}>
						<FormLabel htmlFor="dateOfBirth">Date of Birth</FormLabel>
						<DateOfBirthInput
							name="dateOfBirth"
							id="dateOfBirth"
							minYear={1980}
							maxYear={new Date().getFullYear()}
							value={formik.values.dateOfBirth}
							onChange={(value) => {
								// console.log('Join Page DOB Changed:', value);
								void formik.setFieldValue('dateOfBirth', value);
							}}
						/>
						{formik.errors.lastName && (
							<FormErrorMessage>{formik.errors.dateOfBirth}</FormErrorMessage>
						)}
					</FormControl>
					{/* <HStack justifyContent={'flex-start'} alignItems={'center'} w={'100%'}>
						<Checkbox
							id="allowRemoteAssistance"
							name="allowRemoteAssistance"
							checked={formik.values.allowRemoteAssistance}
							onChange={formik.handleChange}
						>
							Allow remote shopping assistance
						</Checkbox>
						<Popover>
							<PopoverTrigger>
								<IconButton
									aria-label="Remote Shopping Assitance Help"
									variant={'link'}
									size={'xl'}
									icon={<IoHelpCircleOutline />}
								/>
							</PopoverTrigger>
							<PopoverContent>
								<PopoverArrow />
								<PopoverBody fontSize={'sm'}>
									This allows merchants to "see what you see" and take actions on your behalf in
									order to provide better assistance.
								</PopoverBody>
							</PopoverContent>
						</Popover>
					</HStack> */}
					{/* !SECTION */}
					{/* SECTION Address Information Section */}
					<Text fontWeight={'bold'} alignSelf={'flex-start'} mt={'20px'}>
						Address Information
					</Text>
					<Divider />
					<FormControl isRequired isInvalid={Boolean(formik.errors.phoneNumber)}>
						<FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
						<Input
							id="phoneNumber"
							type="tel"
							name="phoneNumber"
							required
							value={formik.values.phoneNumber}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						{formik.errors.phoneNumber && (
							<FormErrorMessage>{formik.errors.phoneNumber}</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isRequired isInvalid={Boolean(formik.errors.address1)}>
						<FormLabel htmlFor="address1">Street Address</FormLabel>
						<Input
							id="address1"
							type="text"
							name="address1"
							required
							value={formik.values.address1}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						{formik.errors.address1 && (
							<FormErrorMessage>{formik.errors.address1}</FormErrorMessage>
						)}
					</FormControl>
					<FormControl>
						<Input
							id="address2"
							type="text"
							name="address2"
							required
							value={formik.values.address2}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							aria-label="Address 2"
						/>
					</FormControl>
					<FormControl isRequired isInvalid={Boolean(formik.errors.country)}>
						<FormLabel htmlFor="country">Country</FormLabel>
						<Select
							id="country"
							name="country"
							placeholder="Select option"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.country}
						>
							{allCountries.map((v, i) => {
								return (
									<option key={`countryOption_${i.toString()}`} value={v[1]}>
										{v[0]}
									</option>
								);
							})}
						</Select>
						{formik.errors.country && <FormErrorMessage>{formik.errors.country}</FormErrorMessage>}
					</FormControl>
					<FormControl isRequired isInvalid={Boolean(formik.errors.state)}>
						<FormLabel htmlFor="state">State/Province</FormLabel>
						<Select
							id="state"
							name="state"
							placeholder="Please select a region, state or province."
							value={formik.values.state}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						>
							{stateData.map((v, i) => {
								return (
									<option key={`stateOption_${i.toString()}`} value={v.code}>
										{v.name}
									</option>
								);
							})}
						</Select>
						{formik.errors.state && <FormErrorMessage>{formik.errors.state}</FormErrorMessage>}
					</FormControl>
					<FormControl isRequired isInvalid={Boolean(formik.errors.city)}>
						<FormLabel htmlFor="city">City</FormLabel>
						<Input
							id="city"
							type="text"
							name="city"
							required
							value={formik.values.city}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						{formik.errors.city && <FormErrorMessage>{formik.errors.city}</FormErrorMessage>}
					</FormControl>
					<FormControl isRequired isInvalid={Boolean(formik.errors.zip)}>
						<FormLabel htmlFor="zip">Zip/Postal Code</FormLabel>
						<Input
							id="zip"
							type="text"
							name="zip"
							required
							value={formik.values.zip}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						{formik.errors.zip && <FormErrorMessage>{formik.errors.zip}</FormErrorMessage>}
					</FormControl>
					{/* !SECTION */}
					{/* SECTION Your Account Section */}
					<Text fontWeight={'bold'} alignSelf={'flex-start'} mt={'20px'}>
						Your Account
					</Text>
					<Divider />
					<FormControl isRequired isInvalid={Boolean(formik.errors.email)}>
						<FormLabel htmlFor="email">Email</FormLabel>
						<Input
							id="email"
							type="email"
							name="email"
							required
							value={formik.values.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						{formik.errors.email && <FormErrorMessage>{formik.errors.email}</FormErrorMessage>}
					</FormControl>
					<FormControl isRequired isInvalid={Boolean(formik.errors.password)}>
						<FormLabel htmlFor="password">Password</FormLabel>
						<InputGroup>
							<InputRightElement>
								<IconButton
									variant="text"
									aria-label={showPassword ? 'Mask password' : 'Reveal password'}
									icon={showPassword ? <HiEyeOff /> : <HiEye />}
									onClick={onToggleShowPassword}
								/>
							</InputRightElement>
							<Input
								id="password"
								name="password"
								type={showPassword ? 'text' : 'password'}
								autoComplete="current-password"
								required
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</InputGroup>
						<Center mt={1}>
							<Progress
								colorScheme={pwStr < 66 ? 'red' : pwStr < 100 ? 'yellow' : 'green'}
								height="32px"
								value={pwStr}
								width={'100%'}
								aria-label="Password Strength"
							/>
							<Text
								pos={'absolute'}
								textShadow={'1px 1px 2px rgba(0,0,0,0.6)'}
								color={pwStr < 66 ? undefined : 'white'}
							>
								{!formik.values.password
									? 'No Password'
									: pwStr < 33
										? 'Too Weak'
										: pwStr < 66
											? 'Weak'
											: pwStr < 100
												? 'Medium'
												: 'Strong'}
							</Text>
						</Center>
						{formik.errors.password && (
							<FormErrorMessage>{formik.errors.password}</FormErrorMessage>
						)}
					</FormControl>

					<FormControl isRequired isInvalid={Boolean(formik.errors.confirmPassword)}>
						<FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
						<InputGroup>
							<InputRightElement>
								<IconButton
									variant="text"
									aria-label={showConfirmPassword ? 'Mask password' : 'Reveal password'}
									icon={showConfirmPassword ? <HiEyeOff /> : <HiEye />}
									onClick={onToggleShowConfirmPassword}
								/>
							</InputRightElement>
							<Input
								id="confirmPassword"
								name="confirmPassword"
								type={showConfirmPassword ? 'text' : 'password'}
								autoComplete="current-password"
								required
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</InputGroup>
						{formik.errors.confirmPassword && (
							<FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
						)}
					</FormControl>
					<Text fontSize={'sm'} textAlign={'left'} alignSelf={'flex-start'}>
						Create a password 8 to 25 characters long that includes at least 1 uppercase and 1
						lowercase letter, 1 number and 1 special character like an exclamation point or
						asterisk.
					</Text>
					{/* !SECTION */}
					<Button
						// textTransform="uppercase"
						variant="solid"
						bg="secondary.500"
						color="white"
						_hover={{ opacity: '0.8' }}
						size="lg"
						mt={'30px'}
						onClick={() => {
							formik.handleSubmit();
						}}
						isLoading={loading}
					>
						Create an Account
					</Button>
				</VStack>
			</Stack>
			<FooterThreeColumn />
			<Modal
				isOpen={showSuccessModal}
				onClose={() => {
					formik.resetForm();
					setShowSuccessModal(false);
				}}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign={'center'}>Registration Successful!</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack spacing="8">
							<Stack spacing={{ base: '2', md: '3' }}>
								<Stack spacing="5">
									<Text>Please check your email for a verification letter.</Text>
									<Text>Before you can login, you must verify your email address.</Text>
								</Stack>
							</Stack>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button
							onClick={() => {
								formik.resetForm();
								setShowSuccessModal(false);
							}}
						>
							Ok
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			{/* NOTE: Confirm Modal */}
			<Modal
				isOpen={showConfirmModal}
				onClose={() => {
					// Do NOTHING, we want to keep this modal open
				}}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign={'center'}>Confirm Your Account</ModalHeader>
					<ModalBody>
						<VStack gap={5} alignItems={'flex-start'}>
							<Text>Please check your email for a verification code.</Text>
							<Text>Before you can login, you must verify your email address.</Text>
							<FormControl isRequired isInvalid={Boolean(formikConfirm.errors.confirmationCode)}>
								<FormLabel htmlFor="confirmationCode">Confirmation Code</FormLabel>
								<Input
									id="confirmationCode"
									type="text"
									name="confirmationCode"
									required
									value={formikConfirm.values.confirmationCode}
									onChange={formikConfirm.handleChange}
									onBlur={formikConfirm.handleBlur}
								/>
								{formikConfirm.errors.confirmationCode && (
									<FormErrorMessage>{formikConfirm.errors.confirmationCode}</FormErrorMessage>
								)}
							</FormControl>
							<HStack justify="space-between" alignItems={'flex-start'} mb={'20px'}>
								<Button
									textTransform={'uppercase'}
									onClick={() => {
										formikConfirm.handleSubmit();
									}}
									isLoading={confirmLoading}
								>
									Confirm
								</Button>
							</HStack>
						</VStack>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
});

export const AnimatedJoinPage = motion(
	React.forwardRef<HTMLDivElement, unknown>((props, ref) => <JoinPage ref={ref} />),
);

export default JoinPage;
