import {
	AspectRatio,
	HStack,
	Image,
	Skeleton,
	Text,
	VStack,
	Link,
	IconButton,
	useToast,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
} from '@chakra-ui/react';
import { FiEye, FiHeart } from 'react-icons/fi';
import { IProductQuickViewModalProps } from './ProductQuickViewModal';
import { IRewardTypeDto } from '../../../domain/models';
import { NavLink, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../data/globalstate';
import { useCartManager } from '../../../domain/hooks';
import { FaShoppingCart } from 'react-icons/fa';

interface Props {
	product: IRewardTypeDto;
	quickViewProps: IProductQuickViewModalProps;
	setQuickViewProps: React.Dispatch<IProductQuickViewModalProps>;
}

const ProductCard: React.FC<Props> = ({ product, setQuickViewProps }) => {
	const { user, appConfiguration } = useContext(AppContext);
	const navigate = useNavigate();
	const { useAddCartItem } = useCartManager();
	const { mutate: addCartItem } = useAddCartItem();
	const toast = useToast();
	let primaryImageUrl = product.imageURL;
	let primaryAltText = product.voucherName;

	if (!primaryImageUrl) {
		const match = product.imageAlternatives?.filter((v) => {
			return v.isPrimary;
		});
		if (match) {
			primaryImageUrl = match[0]?.url;
			primaryAltText = match[0]?.altText;
		}
	}

	return (
		<Card borderRadius={'lg'}>
			<CardHeader
				pb={3}
				backgroundColor={
					product.voucherTypeSubCategory === 'SWEEPSTAKE' ? 'secondary.500' : undefined
				}
				borderTopRadius={'lg'}
			>
				<HStack alignSelf={'stretch'} justifyContent={'center'}>
					{product.voucherTypeSubCategory === 'SWEEPSTAKE' && (
						<HStack
							justifyContent={'space-between'}
							textColor={'white'}
							alignSelf={'stretch'}
							flex={1}
							alignItems={'center'}
						>
							<Text textTransform={'uppercase'} fontSize={'medium'}>
								sweepstakes
							</Text>
							<HStack flex={1} gap={0} justifyContent={'flex-end'} position={'relative'}>
								<VStack
									w={'64px'}
									h={'64px'}
									borderRadius={'32px'}
									backgroundColor={'primary.500'}
									color={'white'}
									gap={0}
									justifyContent={'center'}
									position={'absolute'}
									top={0}
									right={'45%'}
									border={'2px solid white'}
									zIndex={100}
								>
									<Text fontSize={'medium'} fontWeight={'bold'}>
										{product.points?.toLocaleString('en-US')}
									</Text>
									<Text fontSize={'small'}>Points</Text>
								</VStack>
								<IconButton
									icon={<FiEye size={'24px'} />}
									aria-label="View details"
									onClick={() => {
										setQuickViewProps({ isShown: true, product: product });
									}}
									w={'24px'}
									h={'24px'}
									variant={'ghost'}
									color={'white'}
								/>
							</HStack>
						</HStack>
					)}
					{product.voucherTypeSubCategory !== 'SWEEPSTAKE' && (
						<HStack
							justifyContent={'space-between'}
							alignSelf={'stretch'}
							flex={1}
							alignItems={'center'}
						>
							{product.quantity === null && (
								<Text textTransform={'uppercase'} fontSize={'medium'}>
									sold out
								</Text>
							)}
							{product.quantity !== null && (
								<Text textTransform={'uppercase'} fontSize={'medium'} fontWeight={'bold'}>
									{product.quantity > 0 ? `${product.quantity.toString()} available` : 'sold out'}
								</Text>
							)}
							<HStack flex={1} gap={0} justifyContent={'flex-end'} position={'relative'}>
								<VStack
									w={'64px'}
									h={'64px'}
									borderRadius={'32px'}
									backgroundColor={'primary.500'}
									color={'white'}
									gap={0}
									justifyContent={'center'}
									position={'absolute'}
									top={0}
									right={'50%'}
									border={'2px solid white'}
									zIndex={10}
								>
									<Text fontSize={'medium'} fontWeight={'bold'}>
										{product.points?.toLocaleString('en-US')}
									</Text>
									<Text fontSize={'small'}>Points</Text>
								</VStack>
								<IconButton
									icon={<FiHeart size={'24px'} />}
									aria-label="Add to wishlist"
									onClick={() => {
										// TODO Wishlist
										console.log('Add to Wishlist', product);
									}}
									w={'24px'}
									h={'24px'}
									variant={'ghost'}
									zIndex={20}
								/>
								<IconButton
									icon={<FiEye size={'24px'} />}
									aria-label="View details"
									onClick={() => {
										setQuickViewProps({ isShown: true, product: product });
									}}
									w={'24px'}
									h={'24px'}
									variant={'ghost'}
									zIndex={20}
								/>
							</HStack>
						</HStack>
					)}
				</HStack>
			</CardHeader>
			<CardBody py={0} pt={1}>
				<Link as={NavLink} to={product.rewardId ? `/product/${product.rewardId}` : '#'}>
					<AspectRatio ratio={1} borderRadius="lg" backgroundColor={'#FFF'}>
						<Image
							src={primaryImageUrl ?? appConfiguration?.placeHolderImage ?? undefined}
							alt={primaryAltText ?? 'Unknown'}
							draggable="false"
							fallback={<Skeleton />}
							borderRadius="lg"
							// @ts-expect-error Need to add CSS !important
							objectFit={'contain !important'}
						/>
					</AspectRatio>
				</Link>
				<Link as={NavLink} to={product.rewardId ? `/product/${product.rewardId}` : '#'}>
					<Text size={'md'} fontWeight={'bold'} textAlign={'left'}>
						{product.voucherName}
					</Text>
				</Link>
			</CardBody>
			<CardFooter pt={0}>
				<HStack flex={1} justifyContent={'flex-end'}>
					<IconButton
						aria-label="Add to Cart"
						icon={<FaShoppingCart size={'24px'} />}
						variant={'ghost'}
						onClick={() => {
							if (user) {
								// TODO Validate Available Points
								addCartItem({ itemData: product, quantity: 1 });
								toast({
									// title: 'Account created.',
									description: `Added ${product.voucherName ?? ''} to your cart!`,
									status: 'success',
									duration: 5000,
									isClosable: true,
								});
							} else {
								navigate('/login');
							}
						}}
					/>
				</HStack>
			</CardFooter>
		</Card>
	);
};
export default ProductCard;
